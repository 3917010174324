<template>
  <div>

    <div class="box">

      <p class="title is-5 is-small">Date Baza</p>

      <div class="columns">
        <div class="column is-one-third">
          <b-field grouped>
            <b-field label="Rol" expanded>
              <b-input v-model="datePersoana.rol"></b-input>
            </b-field>
            <b-field label="Rol Vechi" expanded>
              <b-input v-model="datePersoana.rol_vechi"></b-input>
            </b-field>
          </b-field>
        </div>

        <div class="column">
          <b-field grouped>
            <b-field label="Denumire" expanded>
              <b-input v-model="datePersoana.denumire"></b-input>
            </b-field>
          </b-field>
        </div>
      </div>
    </div>

    <div class="box">

      <p class="title is-5 is-small">Fiscal</p>

      <div class="columns">
        <div class="column">
          <b-field grouped>
            <b-field label="Cod Fiscal" expanded>
              <b-input v-model="datePersoana.cod_fiscal"></b-input>
            </b-field>
            <b-field label="Cod TVA" expanded>
              <b-input v-model="datePersoana.cod_tva"></b-input>
            </b-field>
            <b-field label="Cod Registru Comert" expanded>
              <b-input v-model="datePersoana.cod_reg_comert"></b-input>
            </b-field>
            <b-field label="Nr Dosar" expanded>
              <b-input v-model="datePersoana.numar_dosar"></b-input>
            </b-field>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <b-field grouped>
            <b-field label="Data Inregistrare Fiscala" expanded>
              <b-datepicker
                v-model="datePersoana.data_inregistrare_fiscala"
                :show-week-number="false"
                locale="en-GB"
                placeholder="Alege Data"
                icon="calendar-today"
                :icon-right="selected ? 'close-circle' : ''"
                trap-focus>
              </b-datepicker>
            </b-field>
            <b-field label="Data Registru Comert" expanded>
              <b-datepicker
                v-model="datePersoana.data_reg_comert"
                :show-week-number="false"
                locale="en-GB"
                placeholder="Alege Data"
                icon="calendar-today"
                :icon-right="selected ? 'close-circle' : ''"
                trap-focus>
              </b-datepicker>
            </b-field>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="block">
            <b-checkbox
              v-model="datePersoana.platitor_tva"
              true-value="1"
              false-value="0"
            >
              Platitor TVA
            </b-checkbox>
          </div>
        </div>
      </div>

    </div>

    <div class="box">

      <p class="title is-5">Adresa</p>

      <div class="columns">

        <div class="column is-half">
          <b-field grouped>
            <b-field label="Localitate" expanded>
              <b-input v-model="datePersoana.oras"></b-input>
            </b-field>
            <b-field label="Strada" expanded>
              <b-input v-model="datePersoana.strada"></b-input>
            </b-field>
          </b-field>
        </div>

        <div class="column">
          <b-field grouped>
            <b-field label="Nr" expanded>
              <b-input v-model="datePersoana.numar"></b-input>
            </b-field>
            <b-field label="Bloc" expanded>
              <b-input v-model="datePersoana.bloc"></b-input>
            </b-field>
            <b-field label="Scara" expanded>
              <b-input v-model="datePersoana.scara"></b-input>
            </b-field>
            <b-field label="Ap." expanded>
              <b-input v-model="datePersoana.apartament"></b-input>
            </b-field>
            <b-field label="Cod Postal" expanded>
              <b-input v-model="datePersoana.cod_postal"></b-input>
            </b-field>
          </b-field>
        </div>

      </div>

      <div class="columns">
        <div class="column">
          <div class="block">
            <b-checkbox
              v-model="datePersoana.adresa_externa"
              true-value="1"
              false-value="0"
            >
              Adresa Externa
            </b-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="box">

      <p class="title is-5">Informatii Suplimentare</p>

      <div class="columns">
        <div class="column">
          <b-field grouped>
            <b-field label="Adresa punct lucru" expanded>
              <b-input v-model="datePersoana.adresa_punct_lucru"></b-input>
            </b-field>
          </b-field>
        </div>
      </div>


      <div class="columns">
        <div class="column">

          <b-field grouped>
            <b-field label="Activitate Principala" expanded>
              <b-input v-model="datePersoana.activitate_principala"></b-input>
            </b-field>
            <b-field label="Banca" expanded>
              <b-input v-model="datePersoana.banca"></b-input>
            </b-field>
            <b-field label="Cont Bancar" expanded>
              <b-input v-model="datePersoana.cont_bancar"></b-input>
            </b-field>
          </b-field>

        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <b-field grouped>
            <b-field label="Email" expanded>
              <b-input v-model="datePersoana.email"></b-input>
            </b-field>
            <b-field label="Numar Telefon" expanded>
              <b-input v-model="datePersoana.numar_telefon"></b-input>
            </b-field>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field label="Mentiuni" expanded>
            <b-input v-model="datePersoana.mentiuni"></b-input>
          </b-field>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "FormularPersoaneJuridice",
  props: [
    "personData",
  ],
  data() {
    return {
      datePersoana: {
        id: null,
        tip: "pj",
        nume: "",
        prenume: "",
        cnp: "",
        serie_ci: "",
        numar_ci: "",
        data_eliberare_ci: undefined,
        autor_ci: "",
        data_nasterii: undefined,
        locul_nasterii: "",
        numarr_pasaport: "",
        data_pasaport: undefined,
        numele_tatalui: "",
        numele_mamei: "",
        loc_munca: "",
        nationalitate: "",
        cetatean_strain: false,
        pfa: false,
        decedat: false,
        cod_tva: "",
        adresa_externa: "",
        denumire: "",
        cod_fiscal: "",
        platitor_tva: false,
        data_inregistrare_fiscala: undefined,
        cod_reg_comert: "",
        data_reg_comert: undefined,
        numar_dosar: "",
        adresa_punct_lucru: "",
        activitate_principala: "",
        rol: "",
        rol_vechi: "",
        email: "",
        numar_telefon: "",
        banca: "",
        cont_bancar: "",
        mentiuni: "",
        strada: "",
        numar: "",
        bloc: "",
        scara: "",
        oras: "",
        judet: "",
        apartament: "",
        cod_postal: "",
      },
      selected: false,
    };
  },
  mounted() {
    if (this.personData) {
      this.datePersoana = this.personData;
    }
  },
  watch: {
    personData(val) {
      if (val) {
        this.datePersoana = val;
      }
    },
    datePersoana: {
      handler(updatedPersonData) {
        this.$emit("personDataChanged", updatedPersonData);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>