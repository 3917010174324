<template>
  <div>
    <p class="title is-3">Editeaza Persoana {{ pf ? 'Fizica' : 'Juridica' }}</p>
    <p v-if="!isLoading" class="subtitle is-5">{{ nume }}</p>

    <b-skeleton v-if="isLoading" width="20%" :animated="isLoading"></b-skeleton>

    <div class="pb-3">
      <b-button @click="back" type="is-danger is-light">Anuleaza</b-button>

      <div class="buttons is-pulled-right">
        <b-button type="is-primary">Registru Agricol</b-button>
        <b-button type="is-primary" disabled>Taxe & Impozite</b-button>
      </div>
    </div>

    <b-skeleton v-if="isLoading" width="100%" height="550px" :animated="isLoading"></b-skeleton>

    <FormularPersoaneJuridice v-if="pj" :personData="datePersoana" @personDataChanged="updatePersonData" />

    <FormularPersoaneFizice v-if="pf" :personData="datePersoana" @personDataChanged="updatePersonData" />

    <b-button type="is-primary" class="mt-3" @click="salveazaPersoana">Salveaza</b-button>

    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import router from '../../../router';
import FormularPersoaneJuridice from './FormularPersoaneJuridice';
import FormularPersoaneFizice from './FormularPersoaneFizice';
import { api } from '../../../network';

export default {
  name: "EditeazaPersoana",
  components: { FormularPersoaneFizice, FormularPersoaneJuridice },
  data() {
    return {
      isLoading: true,
      datePersoana: {},
    }
  },
  mounted() {
    api.get(`person/${this.$route.params.id}`)
        .then(({ data }) => {
          if (!data.personData) {
            this.$buefy.toast.open({
              message: 'A fost o eroare in selectarea de persoana. Va rugam incercati mai tarziu sau contactati un administrator.',
              type: 'is-danger'
            });
            router.back();
          } else {
            this.datePersoana = data.personData;
            this.isLoading = false;
          }
        });
  },
  computed: {
    nume() {
      return this.pf ? `${this.datePersoana?.nume} ${this.datePersoana?.prenume}` : this.datePersoana?.denumire
    },
    pf() {
      return this.datePersoana?.tip === 'pf';
    },
    pj() {
      return this.datePersoana?.tip === 'pj';
    },
  },
  methods: {
    back() {
      router.back();
    },
    updatePersonData(personData) {
      this.datePersoana = personData;
    },
    salveazaPersoana() {

      if (this.pf && (!this.datePersoana.rol.length || !this.datePersoana.rol_vechi.length || !this.datePersoana.nume.length || !this.datePersoana.prenume.length)) {
        this.$buefy.toast.open({
          message: 'Rol, rol vechi, nume, prenume sunt obligatorii!',
          type: 'is-danger'
        });

        return;
      }

      if (this.pj && (!this.datePersoana.rol.length || !this.datePersoana.rol_vechi.length || !this.datePersoana.denumire.length)) {
        this.$buefy.toast.open({
          message: 'Rol, rol vechi, nume, prenume sunt obligatorii!',
          type: 'is-danger'
        });

        return;
      }

      api.post('person/save', {
        personData: this.datePersoana,
      })
      .then(({data}) => {
        if (data.success) {
          this.$buefy.toast.open({
            message: 'Salvat cu succes!',
            type: 'is-success'
          });

          router.back();
        } else {
          this.$buefy.toast.open({
            message: 'A fost o eroare in salvarea datelor. Va rugam incercati mai tarziu sau contactati un administrator.',
            type: 'is-danger'
          });
        }
      })
      .catch(() => {
        this.$buefy.toast.open({
          message: 'A fost o eroare in salvarea datelor. Va rugam incercati mai tarziu sau contactati un administrator.',
          type: 'is-danger'
        });
      })
    },
  }
}
</script>

<style scoped>

</style>